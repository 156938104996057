<template>
  <div class="row wallpaper dev" style="height: 100%; padding: 0">
    <div class="col-8 align-self-center text-center " style="width: 100%">
      <h2 class="text-left strokeme " style="font-size: 2.2vw;width:100%; color:white;">
      <img :src="image" width="20%" height="100px"/>
        {{this.date}} &nbsp;&nbsp;   <a href="#" @click="options"><span style='font-size:2.0rem;' class='bg-opacity-1' >🟢</span></a>
      </h2>
      <div class="col-3 align-self-center text-center d-flex" v-if=show >
        <input type="file" @change="uploadFile"  ref="file" accept="video/mp4,video/x-m4v,video/*" id="actual-btn" hidden>
        <label class="btn btn-primary w-30"  style="height:2.3rem;" for="actual-btn">Upload</label> &nbsp;
        <button @click="deleteMediaFile" style="height:2.3rem;" class="btn btn-danger">Delete</button> &nbsp;
        <!-- <button @click="onPlay" style="height:2.3rem;" class="btn btn-success" >Play</button> -->
      </div>
      <!-- <video muted preload="auto" ref="videoplayer" class="bg-primary" style="width:100%" @ended="videoEnded">
        <source  :src="videoDataOp">
      </video> -->
       <video :src="videoDataOp" class="bg-balck" preload="auto" autoplay muted loop ref="videoplayer" style="width:100%" @ended="videoEnded"></video>
    </div>
    <div class="col-4 " style="height: 100%; padding: 0;width:100%; display: flex;flex-direction: column;">
      <h2 class="text-center strokeme" style="font-size: 2.2vw;width:100%;color:white">
        Now Serving
      </h2>
      <div  v-bind:key="channel.doctor_name" v-for="channel in channels" class="background-color:black; outline: none;">
        <CRow class="background-color:black; outline: none;">
        <CCard class="col-md-12 my-0" style="background-color:black; outline: none;">
          <CCardBody align="center" style="display: flex;font-size:2.2vw;font-weight:bold;color:white;align-items:center;justify-content:center;" @click="webtoken(channel.room_no)">
            {{channel.doctor_name}}
          </CCardBody>
        </CCard>  
        </CRow>
        <CRow class="background-color:black; outline: none;">
        <CCard class="col-md-8 mx-0 px-0  mt-0" style="background-color:white;">
          <CCardBody align="center" class="px-0" style="display: flex;font-size:2.5vw;font-weight:bold;color:black;align-items:center;justify-content:center;" @click="webtoken(channel.room_no)">
            ROOM : {{channel.room_no}}
          </CCardBody>
        </CCard>
        <CCard class="col-md-4  mt-0" style="background-color:white;">
          <CCardBody align="center" style="display: flex;margin:0;padding:0;color:black;font-size:5.5vw;font-weight:bold;align-items:center;justify-content:center;" @click="webtoken(channel.room_no)">
            {{Number(channel.token_no).toString().padStart(2, '0')}}
          </CCardBody>
        </CCard>
        </CRow>
      </div>
    </div>
    
  </div>
</template>

<script>
/*eslint-disable*/
import idb from '../../api/idb'
import image from '../../assets/logo.jpeg'
// import test from 'public/avactors/1.jpg' 
export default {
  name: "Login",
  inject: ["api"],
  data: function () {
    return {
      active: null,
      room_no: null,
      image: image,
      break: 1,
      idb_id:0,
      media:[],
      show:false,
      videoDataOp:null,
      channels: [],
      videoindex: 0,
      beforeValue: 0,
      PresentValue: 0,
      a: [],
      b: [],
      date: (new Date()).toLocaleString('en-GB'),
      fields: [
        { filter: false, sorter: false, key: "token_no", label: "Token" ,width:"10%"},
       {
          filter: false,
          sorter: false,
          key: "doctor_name", width:"10%",
          label: "Doctor Name",
        }, 
        { filter: false, sorter: false, key: "room_no", label: "Room" },  
      ],
      videofiles: [],
      audiofiles: [],
      // This for loop bug fix to menual
      room1: null,
      room2: null,
      room1: null,
      room2: null,
      r1p: null,
      r2p: null,
      Token1: null,
      Token2: null,
      tokenData:null,
      tokenData1:null,
      tokenData2:null,
      room:[],
      rp:[],
      Token:[],
      rA:[]
    };
  },
 
  updated(){
  },

  created() {
    this.onStartup();
      setInterval(async () => {
        this.date = (new Date()).toLocaleString('en-GB')
      }, 100);
			setInterval(async () => {
        this.getChannels();
      }, 5000);
      
     this.indexDBReload();
  },
  methods: {

  async indexDBReload(){
    idb.getMedia().then((x)=>  {this.media = x;this.onPlay();})
  },  
  
  async deleteMediaFile(){
    let id = this.idb_id
    let array = this.media.map((i) =>(i.id))
    
    for (let i =0; i < array.length; i++){
      idb.deleteMedia((array[i]));
    }
      this.indexDBReload();
      this.videoDataOp = ''
      this.show = true 
    },

    videoEnded() {
      this.videoindex++;
      if(this.videoindex >= this.videofiles.length){
        this.videoindex = 0;
      }
      this.$refs.videosrc.src = this.videofiles[this.videoindex].src;
      this.$refs.videosrc.type = this.videofiles[this.videoindex].type;
      this.$refs.videoplayer.load()
      this.$refs.videoplayer.play()
    },
// From cloud Video stream
    async loadVideos() {
      this.videofiles = [];
      let result = await this.api.get("videolist");
      let files = result.data;
      let baseurl = this.api.defaults.baseURL
      files.forEach((file) => {
        let video = {
          id: file,
          src: baseurl + "/videos/" + file,
          type: "video/mp4",
        };
        this.videofiles.push(video); 
      });
      let data =this.videofiles
    },

async uploadFile(e){
  let videoFile = e.target.files[0]
  let reader = new FileReader();
			reader.readAsDataURL(videoFile);
      reader.onload = (e) =>{
          let ab = e.target.result;
          this.onUpload(ab)
        };     
},

async onUpload(v){
  let ob = {}
      ob.created = new Date(),
      ob.data = v
  await this.$store.dispatch('saveMedia', ob);
  // location.reload(true)
  this.onPlay();
},

async onPlay(){
  let av = this.media;
  let videoArray = av.map((x)=>({"data":x.data,"id":x.id}));
  this.videoDataOp = (videoArray[0].data).toString();
  this.idb_id = videoArray[0].id;
  this.$refs.videoplayer.play()
  this.show = false
},

async options(){
    this.show = true
  }, 

async getChannels() {
      const queryString = window.location
      var url = new URL(queryString);
      var ab = url.hash
      let room_no = ab.split("#/room/")[1]
      let int_room_no = String(room_no).padStart(2, '0')

      if( room_no !== undefined){
          let result = await this.api.get(`channels/today/${int_room_no}`);
          this.channels = result.data
          this.PresentValue = this.channels[0].token_no
          this.room_no = this.channels[0].room_no
          let PresentValue = this.PresentValue
          let beforeValue = this.beforeValue
          if(beforeValue !== PresentValue){
           // this.onsound();
          }
        }else{
          let result = await this.api.get(`channels/today`);
          this.channels = result.data
          for (let index = 1; index < 10; index++) {
            this.tokendisplay(index.toString().padStart(2, '0'));
            this.tokendisplay(index);
          }
        }
      
    },
   
    async tokendisplay (no ){
      let result = await this.api.get(`channels/today/${no}`);
      var Data1 = result.data
      this.Token[no] = Data1[0].token_no
      this.room[no] = parseInt(Data1[0].room_no)
      this.rp[no] = this.Token[no]
      if( 0 < Data1.length){
        if (this.rp[no] !== this.rA[no]) {
            var msg = new SpeechSynthesisUtterance();
            var voices = window.speechSynthesis.getVoices();
            msg.voice = voices.filter(function(voice) { return voice.name == 'Google UK English Male'; })[0];
             msg.volume = 100;
             msg.lang = 'en-UK';
             msg.text = `Token Number ${this.Token[no]}  Room No ${this.room[no]}`
             window.speechSynthesis.speak(msg);
             this.rA[no] = this.rp[no]
        }
      }else{
        //
      }
    },
    async tokendisplay2 (){
      let result = await this.api.get(`channels/today/02`);
      const Data2 = result.data
      this.room2 = Data2[0].token_no
      this.Token2 = parseInt(Data2[0].room_no)
      this.r2p = this.room2
      if(0 < Data2.length) {
         if(this.r2p !== this.r2A){
           var msg = new SpeechSynthesisUtterance();
             msg.volume = 100;
             msg.rate = 0.7;
             msg.pitch = 5;
             msg.lang = 'en-UK';
             msg.text = `Token Number ${this.room2}  Room No ${this.Token2}`;
            window.speechSynthesis.speak(msg);
            this.r2A = this.r2p
        }   
      }
    },

    async onsound (){ 
      let PresentValue = this.PresentValue
      let RoomNumber = parseInt(this.room_no);
         var msg = new SpeechSynthesisUtterance();
             msg.volume = 100;
             msg.rate = 0.7;
             msg.pitch = 5;
             msg.lang = 'en-UK';
             msg.text = `Token Number ${PresentValue}  Room No ${RoomNumber}`;
        window.speechSynthesis.speak(msg);
        this.beforeValue  = this.PresentValue
    },
    async onStartup() {
      await this.loadVideos();
      this.$refs.videosrc.src = this.videofiles[this.videoindex].src;
      this.$refs.videosrc.type = this.videofiles[this.videoindex].type;
      this.$refs.videoplayer.load()
      this.$refs.videoplayer.play()
       this.getChannels();

    },
    
    webtoken(room_no){
      let room = room_no
      const queryString = window.location
      var url = new URL(queryString);
      var hostname = url.href
      if (confirm('Are you sure you want to redirect Single TOKEN Display tab?')) {
          // window.location.href = `${hostname}room/${room}`;
          window.open(`${hostname}room/${room}`, '_blank'); // have to new tab
        } else {
          //
        }
      
    }
  },
};
</script>

<style>
.strokeme
{
    color: white;
    text-shadow:
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;  
}
</style>
<style lang="sass" scoped>
.wallpaper
  position: relative
  background: #979797
  overflow: hidden
.wallpaper .q-page-container
  position: relative
  z-index: 2
  overflow: hidden
.wallpaper:before
  content: ' '
  display: block
  position: absolute
  width: 100%
  height: 100%
  z-index: 0
  opacity: 29
.wallpaper.dev:before
 
  background: url(~../../assets/image/back6.jpg) repeat
</style>